<template>
  <div>
    <b-modal
      id="new-appointment-form-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="closeModal(false)"
    >
      <div class="modal-header">
        <div class="modal-title">Adicionar {{ title }}</div>
        <span class="icon-box">
          <Close class="icon stroke" @click="closeModal(false)" />
        </span>
      </div>
      <div class="around-content">
        <validation-observer>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="nome_consulta">{{ labelName }}</label>
                <b-input
                  id="nome_consulta"
                  autocomplete="off"
                  :placeholder="labelName"
                  v-model="form.name"
                  maxlength="20"
                />
                <div
                  v-if="validated && !form.name"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
                <div
                  v-if="String(form.name).length === 20"
                  class="custom-invalid-feedback"
                >
                  Por favor, insira até 20 caracteres
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="descricao">Descrição</label>
                <div>
                  <b-form-textarea
                    id="descricao"
                    v-model="form.description"
                    placeholder="Descrever"
                    rows="3"
                    max-rows="6"
                  />
                  <div
                    v-if="validated && !form.description"
                    class="custom-invalid-feedback"
                  >
                    Campo obrigatório
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <hr />

          <div class="wrapper-button">
            <b-button variant="primary" size="lg" @click="createCustomForm">
              Criar {{ title.replace(/\b(nova|novo)\b/gi, '') }}
            </b-button>
          </div>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'NewAppointmentFormModal',
  props: {
    person: Object,
    clinic: Object,
    type: {
      type: String,
      default: 'ATTENDANCE',
      validator: value => {
        return ['ATTENDANCE', 'EXAM'].includes(value)
      }
    }
  },
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  data() {
    return {
      form: {
        name: null,
        description: null
      },
      validated: null
    }
  },
  computed: {
    title() {
      return this.type === 'ATTENDANCE'
        ? 'nova consulta personalizada'
        : 'novo exame personalizado'
    },
    labelName() {
      return this.type === 'ATTENDANCE' ? 'Nome da consulta' : 'Nome do exame'
    }
  },
  methods: {
    closeModal() {
      this.clearForm()
      this.$bvModal.hide('new-appointment-form-modal')
    },
    clearForm() {
      this.form = {
        name: null,
        description: null
      }
      this.validated = null
    },
    isValidForm() {
      this.validated = true
      return this.form.name && this.form.description
    },
    async createCustomForm() {
      if (!this.isValidForm()) {
        return
      }

      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.createCustomForm({
          name: this.form.name,
          description: this.form.description,
          active: true,
          clinic_id: this.clinic.id,
          professional_id: this.person.id,
          type: this.type
        })

        this.$toast.success('Consulta personalizada criada com sucesso!')
        this.$router.push(`configuracoes-da-consulta/${data.id}`)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>

<style lang="scss">
#new-appointment-form-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
